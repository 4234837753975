@font-face {
  font-family: 'alliance_1';
  src: url('fonts/alliance_no.1_regular-webfont.woff2') format('woff2'),
       url('fonts/alliance_no.1_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'alliance_2';
  src: url('fonts/alliance_no.2_regular-webfont.woff2') format('woff2'),
       url('fonts/alliance_no.2_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* USED FONTS 
font-family: 'Poppins', sans-serif;
*/

:root{
  --purple-color: #273458;
  --black-color:#000000 ;
  --white-color:#ffffff ;
  --grey-color: #f2f3f6;
  --text-color: #273458;
  --border-color: #1e2124;
  /* --blue-grad: linear-gradient(45deg, var(--darkblue-color) 0%, var(--blue-color) 100%);  */
  --ease: all 400ms ease-in-out;
}

body{
  font-family: 'alliance_1';
  font-size: 16px;
  color: var(--text-color);
}

a{
  text-decoration: none;
  color: var(--text-color);
  transition: var(--ease);
}
h1,h2,h3,h4{
  font-family: 'alliance_2';
}

.magic-ani { 
  position: relative;    
}

.magic-ani::after{
  position: absolute;
  content: '';
  left: 0;
  height: 1px;
  width: 0;
  background-color: var(--border-color);
  transition: all 2s ease-in-out;
  bottom: 0;  
}

.hrAnimation::after{
width: 100%;
}

/* HEADER */
.btn-black-outline {
  background-color: transparent;
  color: var(--text-color);
  border-color: var(--border-color);
}

.btn-style {
  border-radius: 0;
  border: 1px solid;
  transition: var(--ease);
}

.btn-black-outline:hover {
  background-color: var(--purple-color);
  border-color: var(--purple-color);
}

.header-nav {
  display: flex;
  align-items: center;
  gap: 35px;
  justify-content: end;
}

.start-btn svg {
  transform: rotate(-35deg);
  margin-right: 3px;
}

.start-btn button {
  width: 200px;
  padding: 7px 10px;
}

.menu-btn button:first-child {
  border-right: none;
}

.menu-btn button {
  padding: 7px 12px;
}

.header-top {
  padding: 35px 0 15px;
}

.header-bottom nav {
  padding: 0 0;
  margin: 10px 0 0;
  background: none !important;
}

.header-bottom .navbar-nav a {
  padding: 10px 0;
  color: var(--text-color);
  text-decoration: none;
  opacity: .8;
  font-size: 16px;
  position: relative;
  transition: var(--ease);
}

.header-bottom .container {
  padding: 0;
}

.header-bottom .navbar-nav {
  gap: 25px;
}

.header-bottom .navbar-nav a:after {
  content: '';
  left: 0;
  top: 0;
  height: 1px;
  width: 0;
  background-color: var(--border-color);
  position: absolute;
  transition: var(--ease);
}

.header-bottom .navbar-nav a:hover:after {
  width: 25px;
}

.header-bottom .navbar-nav a:hover {
  opacity: 1;
}

/* START offcanvas */

.offcanvas.offcanvas-end.show {
  width: 50%;
}

.offcanvas-body {
  padding: 15px 30px;
}

.Offcanvas_contact {
  display: flex;
  justify-content: space-between;
}

.Offcanvas_contact span {
  font-size: 10px;
}

.Offcanvas_contact_link{
  border-top: 1px solid #000;
}
.Offcanvas_contact_link:hover{
  opacity: 0.5;
}
.Offcanvas_contact_link svg{
  transform: rotate(35deg);
}
.Offcanvas_contact_link:hover svg{
  transition: all;
  margin-bottom: 2px;
  margin-left: 2px;
}

.Offcanvas_main_title{
  margin: 50px 0px;
  max-width: 25em;
}

form.Offcanvas_form {
  margin-bottom: 50px;
}

.Offcanvas_form_input {
  border-bottom: 1px solid #dee2e6;
}

.Offcanvas_form_input input {
  border: none; 
}

.Offcanvas_form_input select {
  border: none;
  font-size: 14px;
}

.Offcanvas_form_label{
  display: flex;
}

label.Offcanvas_form_label.form-label {
  font-size: 11px;
}

.mktoAsterix{
  color: red;
  display: inline;
  order: 2;
  padding-left: 5px;  
}

.Offcanvas_footer span {
  font-style: italic;
}

/* START offcanvas */
/* START MODAL */

.modal-content {
  background: #1e2124;
}

.Offcanvas_form_input2 input {
  background: transparent;
  font-size: 50px;
  /* color: #757575; */
  border: transparent;
}

.Offcanvas_form_input2 input:focus {
  color: #FFF;
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

form.search_model {
  margin-top: 180px;
}

.Offcanvas_form_input2 input::placeholder {
  color: #757575;
}

.nav_modal a{
  font-size: 20px !important;
  color: #fff !important;
  text-decoration: underline !important;
}
.nav_modal p{
  font-size: 12px;
  color: #fff !important;
}

.btn-style2 {
  border-radius: 0;
  border: 1px solid #fff;
  transition: var(--ease);
}

.btn-black-outline2 {
  background-color: transparent;
  color: #fff;
}

.btn-style2 svg{
  color: #fff;
}



/* END MODAL */
/* END HEADER */

/* BANNER */

.banner h1 {
  font-size: 95px;
  max-width: 960px;
  margin: 0 0 60px;
}

.banner {
  padding: 80px 0 0;
}


.divider {
  padding: 60px 0;
}

.ai-assist .sec-head {
  max-width: 600px;
  margin: 0 0 60px;
}

.sec-head h2 {
  font-size: 45px;
}


.aia-details {
  margin-left: 110px;
}

.aia-details .ai-col-01 p {
  font-size: 30px;
  max-width: 84%;
}

.aia-details .ai-col-02 p {
  font-size: 17px;
}

.aia-details .ai-col-02 a {
  color: var(--purple-color);
  text-decoration: none;
  display: block;
  padding: 12px 0;
  font-size: 16px;
}

.aia-details .ai-col-02 a svg {
  float: right;
  margin: 3px 0 0;
}

/* END BANNER */

/* BOOTCAMP */
.img-holder img {
  width: 100%;
}

.btn-black {
  border: 1px solid transparent;
  text-decoration: none;
  box-shadow: none;
  background-color: var(--purple-color);
  color: var(--white-color);
  padding: 12px 30px;
  display: inline-block;
  font-size: 18px;
}

.bootcamp .btn-style svg {
  margin-left: 10px;
}

.bootcamp .btn-style {
  margin-top: 35px;
}

.tc-col span {
  text-transform: uppercase;
  font-size: 11px;
  display: block;
  margin: 0 0 10px;
}

.tc-col h4 {
  font-size: 30px;
  margin: 0 0 20px;
}

.three-cols {
  margin: 80px 0 0;
  padding: 0 100px;
}

.three-cols a:hover {
  opacity: .7;
}

/* END BOOTCAMP */

/* TECH */
.dt-box a:before {
  content: '↳ ';
  position: absolute;
  left: 0;
  top: -3px;
  font-size: 15px;
  
}

.dt-box a {
  position: relative;
  padding: 0 0 0 18px;
  border-bottom: 1px solid;
}

.def-tech .sec-head {
  max-width: 460px;
  margin: 0 0 70px;
}

.dt-box h4 {
  font-size: 30px;
  margin: 0 0 20px;
}

.dt-box a:hover {
  opacity: .6;
}
/* END TECH */

/* PLATFORM */
.platform h4 {
  font-size: 30px;
}

.platform h3 {
  font-size: 30px;
  position: relative;
  padding-left: 35px;
}

.platform h3:before {
  content: '';
  width: 23px;
  height: 21px;
  position: absolute;
  left: 0;
  top: 4px;
  background-image: url(assets/arr.png);
  background-repeat: no-repeat;
}

.linked-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.linked-text p {
  font-size: 18px;
}

.linked-text a {
  padding: 10px 0;
  font-size: 18px;
  display: inline-block;
  margin: -10px 0 0;
  color: #747474;
}

.linked-text a svg {
  margin-left: 15px;
}

/* END PLATFORM */
/* STAR FOOTER */

.footer_youtube_icon {
  display: inline-flex;
  grid-gap: 12px;
  gap: 12px;
  justify-content: center;
  margin-bottom: 10px;
  padding-left: 0;
}

.footer_youtube_icon a {
  font-size: 25px;
}

.footer_us_link ul li a{
  color: #0d6efd;
}
.footer_us_link ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  gap: 10px;
  font-size: 12px;
  margin-top: 10px;
}

.footer_cookies{
  overflow: hidden;
  }

.footer_cookies a{
  position: relative;
  transition: all .35s ease-in-out;
}

.footer_cookies a:hover{
  margin-left: 15px;
  color: #8a8a8a;
}

.footer_cookies a:before{
  color: currentColor;
  content: "\2014";
  height: 100%;
  position: absolute;
  right: 100%;
  margin-right: 2px;
}

.ftr-links span {
  font-size: 11px;
  color: #767676;
}

.ftr-links ul {
  list-style-type: none;
  padding: 0;
}

.ftr-links ul li{
  margin-top: 5px;
  overflow: hidden;
  padding-right:10px;
}
.ftr-links ul li a {
  position: relative;
  transition: all .35s ease-in-out;
}
.ftr-links ul li a:hover {
  margin-left: 13px;
  color: #8a8a8a;
}

.ftr-links ul li a::before{
  color: currentColor;
  content: "\2014";
  height: 100%;
  position: absolute;
  right: 100%;
}

/* END FOOTER */



/*********** Side Navigation Modal  CSS Start ********/


.header_navigation_modal {
  border-top: 1px solid #fff;
}

.header_navigation_modal h6 {
  padding: 10px 0px 10px 0px;
  color: gray;
  margin: 0px 0px 20px 0px;
}

.header_navigation_modal ul li a {
  font-size: 35px;
  color: #fff;
}

.header_navigation_modal ul li {
  list-style: none;
  margin: 5px 0px 5px 0px;
}

.header_navigation_modal ul {
  padding: 0px;
}

.latest_news_modal_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.latest_news_modal_head h6 {
  font-size: 12px;
  color: gray;
  text-transform: uppercase;
  margin: 10px 0px 0px 0px;
}

.header_modal_blogs {
  border-top: 1px solid #fff;
}

.latest_news_modal_head a {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration-line: underline;
}

.latest_news_modal_head a svg {
  transform: rotate(-61deg);
  font-size: 10px;
}
.header_blog_box img {
  width: 100%;
}

.header_blog_box {
  margin: 30px 0px 0px 0px;
}

.header_blog_box p {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0px 0px 10px 0px;
}

.header_modal_blogs a {
  color: #fff;
}

.header_blog_box a {margin: 11px 0px 0px 0px;display: inline-block;font-size: 16px;}


.header_impact_study {
  margin: 30px 0px 0px 0px;
  border-top: 1px solid #fff;
}

.impact_study_btn {
  padding: 15px 0px;
  text-align: end;
}

.impact_study_btn a {
  font-size: 12px;
  text-transform: uppercase;
  text-decoration-line: underline;
}

.impact_study_btn a svg {
  transform: rotate(-61deg);
}

.impact_study_btn h5 {
  text-align: left;
  color: #fff;
  font-size: 18px;
  padding: 10px 0px 0px 0px;
}


.video-react .video-react-big-play-button {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 100px;
  width: 100px;
  line-height: 100px;
}

.tc-col {
  margin: 0 0 40px;
}

.video-react-poster {
  background-size: cover !important;
}

.ftr-img img {
  width: 100%;
  max-width: 150px;
}
/*********** Side Navigation Modal  CSS End ********/